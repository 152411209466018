import React from 'react';
import ModalOverlay from '../ModalOverlay/ModalOverlay';
import NcaaWbbTeamDataInfoModal from '../../pages/NcaaWbb/TeamDataInfo/NcaaWbbTeamDataInfo';

export default function NcaaWbbDataInfoModal() {
    return (
        <ModalOverlay
            heading="NCAA Women's Basketball Data Coverage"
            subheading="Stats are complete back to 2009-10. See notes for exceptions."
            buttonText="See exceptions for stats that go further back."
            buttonContextualInfo="Stats complete back to 2009-10. "
        >
            <NcaaWbbTeamDataInfoModal />
        </ModalOverlay>
    );
}