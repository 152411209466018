const bigEastNotes = 'Big East game scores back to 2002-03';

const backInTimeInfo = [
  {
    categoryName: 'Regular Season',
    teamRecord: '2009-10',
    gameScores: '2009-10',
    playerAndTeamStats: '2009-10',
    notes: bigEastNotes,
  },
  {
    categoryName: 'NCAA Tournament',
    teamRecord: '2009-10',
    gameScores: '2008-09',
    playerAndTeamStats: '2009-10',
    notes: '',
  },
  {
    categoryName: 'NIT Tournament',
    teamRecord: '2009-10',
    gameScores: '2009-10',
    playerAndTeamStats: '2009-10',
    notes: '',
  },
  {
    categoryName: 'Conference Tournaments',
    teamRecord: '2009-10',
    gameScores: '2009-10',
    playerAndTeamStats: '2009-10',
    notes: bigEastNotes,
  },
  {
    categoryName: 'Poll Ranked Teams',
    teamRecord: '2002-03',
    gameScores: '2002-03',
    playerAndTeamStats: '2009-10',
    notes: '',
  },
];

export default backInTimeInfo;







																
										
