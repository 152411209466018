import React from 'react';
import StyledTable from '../../../atoms/StyledTable/StyledTable';
import backInTimeInfo from './constants';

const headers = [
  'Category',
  'Team W-L Record',
  'Game Scores',
  'Player & Team Game Stats',
  'Notes',
];

const rowMap = {
  'Category': 'categoryName',
  'Team W-L Record': 'teamRecord',
  'Game Scores': 'gameScores',
  'Player & Team Game Stats': 'playerAndTeamStats',
  'Notes': 'notes',
}

function NcaaWbbTeamDataInfoModal() {
  return (
      <StyledTable
        uniqueRowKey='No'
        headers={headers}
        data={backInTimeInfo}
        dataMap={rowMap}
      />
  );
}

export default NcaaWbbTeamDataInfoModal;
