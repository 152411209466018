import { FranchiseDetailType, FranchiseHistoryType, FranchiseSeasonType } from "../components/types/franchise";
import { GameBoxScoreType } from "../components/types/game";
import { PlayerProfileStatsType, PlayerProfileType } from "../components/types/player";

type PaginatedData = {
  count: number;
  next: string | null;
  previous: string | null;
};

export interface PlayerGameData extends PaginatedData {
  results: PlayerGameResult[] | GroupedPlayerInstance[];
  query: ResultQuery;
}

export type Franchise = {
  franchise_id: number;
  season: number;
  full_name: string;
  short_name?: string;
  city: string;
  abbr_3: string;
};

export type Game = {
  id: number;
  game_class: string;
  game_class_display: string | null;
  game_class_type: string | null;
  game_class_detail: string | null;
  disposition: string; // TODO: should be an enum of some sort?
  date: string;
  home_score: number;
  visitor_score: number;
  overtime_played: boolean;
  home: number;
  visitors: number;
  tournament_round?: string;
};

export type GameFranchise = {
  game_number: number;
  game_class: string;
  season: number;
  result: string;
  enter_w: number;
  enter_l: number;
  series_w: number;
  series_l: number;
  wins_to_clinch: number;
  won_series: boolean;
  can_clinch: boolean;
  can_be_eliminated: boolean;
  decisive: boolean;
  ahead_in_series: boolean;
  behind_in_series: boolean;
  tied_in_series: boolean;
  score_difference: number;
};

export const GameClass = ['SEM', 'CON', 'RD1', 'FIN'];
export const GameClassType = ['AS', 'PIN', 'PRE', 'PS', 'REG', 'CC'];

export interface PlayerGameResult {
  player: PlayerInfo;
  game: Game;
  franchise: Franchise;
  opponent: Franchise;
  game_franchise: GameFranchise;

  active: boolean;
  age: string;
  assists: number;
  blocks: number;
  complete_game: boolean;
  defensive_rebounds: number;
  disqualified: boolean;
  double_double: boolean;
  dunks: number;
  field_goal_attempts: number;
  field_goals_made: number;
  field_goal_percentage: number;
  franchise_rank?: number;
  franchise_score: number;
  franchise_tournament_seed?: number;
  free_throw_attempts: number;
  free_throws_made: number;
  free_throw_percentage: number;
  game_result: string;
  home_game: boolean | string;
  id: string;
  minutes?: number;
  minutes_display?: number;
  offensive_rebounds: number;
  opening_day: boolean;
  opponent_rank?: number;
  opponent_score: number;
  opponent_tournament_seed?: number;
  personal_fouls: number;
  played: boolean;
  plus_minus: number;
  points: number;
  points_in_paint: number;
  points_off_turnovers: number;
  record_type?: string;
  score_difference: number;
  second_chance_points: number;
  started: boolean;
  steals: number;
  three_point_field_goal_attempts: number;
  three_point_field_goals_made: number;
  three_point_field_goal_percentage: number;
  total_rebounds: number;
  triple_double: boolean;
  turnovers: number;
  round?: string;
  opponent_abbr: string;
};

export type BaseTeamGameResult = {
  game: Game;
  franchise: Franchise;
  opponent: Franchise;
  game_franchise: GameFranchise;

  record_type: string;
  active: boolean;
  age: string;
  assists: number;
  blocks: number;
  complete_game: boolean;
  defensive_rebounds: number;
  double_double: boolean;
  dunks: number;
  field_goal_attempts: number;
  field_goals_made: number;
  field_goals_difference: number;
  field_goal_percentage: number;
  field_goal_percentage_difference: number;
  franchise_abbr: string;
  franchise_name: string;
  franchise_rank: number;
  franchise_score: number;
  franchise_season_game_number?: number;
  franchise_tournament_seed?: number;
  franchise_win_percentage: number;
  free_throw_attempts: number;
  free_throws_difference: number;
  free_throws_made: number;
  free_throw_percentage: number;
  game_result: string;
  home_game: string;
  id: string;
  last_game_result: string;
  minutes_display: number;
  offensive_rebounds: number;
  opening_day: boolean;
  opponent_abbr: string;
  opponent_points_half_1: number;
  opponent_points_half_2: number;
  opponent_rank: number;
  opponent_score: number;
  opponent_tournament_seed?: number;
  opponent_win_percentage: number;
  personal_fouls: number;
  personal_fouls_difference: number;
  played: boolean;
  plus_minus: number;
  points: number;
  points_diff_half_1: number;
  points_diff_half_2: number;
  points_difference: number;
  points_half_1: number;
  points_half_2: number;
  points_in_paint: number;
  points_off_turnovers: number;
  round: string;
  second_chance_points: number;
  started: boolean;
  steals: number;
  steals_difference: number;
  season_game_number: number;
  three_point_field_goal_attempts: number;
  three_point_field_goals_difference: number;
  three_point_field_goals_made: number;
  three_point_field_goal_percentage: number;
  total_rebounds: number;
  total_rebounds_difference: number;
  triple_double: boolean;
  turnovers: number;
  turnovers_difference: number;
  win_percentage_difference: number;
};

export type BasePlayerGameTableRow = {
  assists: number;
  blocks: number;
  defensive_rebounds: number;
  double_double: string;
  dunks: number;
  field_goal_attempts: number;
  field_goals_made: number;
  field_goal_percentage: string;
  franchise_display_name?: string;
  franchise_id: number;
  franchise_season__abbr_3: string;
  free_throw_attempts: number;
  free_throws_made: number;
  free_throw_percentage: string;
  game__date: string;
  game__game_class: string | JSX.Element;
  game__game_class_string?: string;
  game_id: number;
  id: string;
  loc: string;
  minutes_display?: number;
  offensive_rebounds: number;
  opponent_id: number;
  opponent_season__abbr_3: string;
  personal_fouls: number;
  player__display_name: string;
  player__player_id: number | null;
  plus_minus: number;
  points: number;
  points_in_paint: number;
  points_off_turnovers: number;
  rank: number;
  record_type?: string;
  result: JSX.Element;
  result_string: string;
  score_difference: number;
  second_chance_points: number;
  started: number;
  steals: number;
  three_point_field_goal_attempts: number;
  three_point_field_goals_made: number;
  three_point_field_goal_percentage: string;
  total_rebounds: number;
  triple_double: string;
  turnovers: number;
};

export type TeamGameTableRow = {
  assists: number;
  blocks: number;
  defensive_rebounds: number;
  double_double: string;
  dunks: number;
  field_goal_attempts: number;
  field_goals_difference: number;
  field_goals_made: number;
  field_goal_percentage: string;
  field_goal_percentage_difference: string;
  franchise_display_name?: string;
  franchise_id: number;
  franchise_rank: number;
  franchise_season__abbr_3: string;
  franchise_win_percentage: number;
  free_throw_attempts: number;
  free_throws_difference: number;
  free_throws_made: number;
  free_throw_percentage: string;
  game__date: string;
  game__game_class: string | JSX.Element;
  game__game_class_string?: string;
  game_id: number;
  id: string;
  loc: string;
  last_game_result: string;
  minutes_display: number;
  offensive_rebounds: number;
  opponent_id: number;
  opponent_rank: number;
  opponent_score: number;
  opponent_season__abbr_3: string;
  opponent_win_percentage: number;
  personal_fouls: number;
  personal_fouls_difference: number;
  plus_minus: number;
  points: number;
  points_difference: number;
  points_in_paint: number;
  points_off_turnovers: number;
  rank: number;
  result: JSX.Element;
  result_string: string;
  round: string;
  score_difference: number;
  season_game_number?: number;
  second_chance_points: number;
  steals: number;
  steals_difference: number;
  three_point_field_goal_attempts: number;
  three_point_field_goals_difference: number;
  three_point_field_goals_made: number;
  three_point_field_goal_percentage: string;
  total_rebounds: number;
  total_rebounds_difference: number;
  triple_double: string;
  turnovers: number;
  turnovers_difference: number;
  win_percentage_difference: number;
};

export type GroupedPlayerInstance = {
  player_id: number;
  display_name: string;
  count: number;
  first: number;
  last: number;
  games: number[];
  season: number;
  franchise?: {
    franchise_id: number;
    full_name: string;
  };
  opponent?: {
    franchise_id: number;
    full_name: string;
  };
};

export type GroupedTeamInstance = {
  season: number;
  franchise: {
    franchise_id: number;
    full_name: string;
  };
  opponent: {
    franchise_id?: number;
    full_name?: string;
  };
  count: number;
  first: number;
  last: number;
  games: number[];
  game_classes: string[];
};

export interface PlayerData extends PaginatedData {
  results: PlayerInfo[];
}

export type PlayerInfo = {
  id: number;
  esbid: string;
  display_name: string;
  first_name: string;
  last_name: string;
  suffix?: string;
  active: boolean;
};

export type ResultQuery = {
  back_to: ResultDetail[];
};

export type ResultDetail = {
  id: number;
  name?: string;
  slug?: string;
  api_name?: string;
  first_season?: number;
  final_season?: number;
  explanation?: string;
  type?: string;
  abbreviation?: string;
  ae_filter_context?: string;
};

export type TeamInfo = {
  id: number;
  full_name: string;
  franchise_id: number;
};

export interface TeamData extends PaginatedData {
  results: TeamInfo[];
};

export type ConferenceInfo = {
  id: number;
  full_name: string;
  conference_id: number;
};

export interface ConferenceData extends PaginatedData {
  results: ConferenceInfo[];
};

export type VenueInfo = {
  venue: number;
  venue_name: string;
};

export interface VenueData extends PaginatedData {
  results: VenueInfo[];
};

export interface FranchiseHistoryData extends PaginatedData {
  results: FranchiseHistoryType[];
};

export interface FranchiseDetailData extends PaginatedData {
  results: FranchiseDetailType[];
};

export interface FranchiseSeasonData extends PaginatedData {
  results: FranchiseSeasonType[];
};

export interface PlayerProfileData extends PlayerProfileType {};

export interface GameBoxScoreData extends GameBoxScoreType {};

export interface PlayerProfileStatsData extends PlayerProfileStatsType {};

export type PlayerStreakResultStats = {
  assists: number;
  blocks: number;
  defensive_rebounds: number;
  dunks: number;
  field_goal_attempts: number;
  field_goals_made: number;
  free_throw_attempts: number;
  free_throws_made: number;
  offensive_rebounds: number;
  personal_fouls: number;
  plus_minus: number;
  points: number;
  points_in_paint: number;
  points_off_turnovers: number;
  second_chance_points: number;
  steals: number;
  three_point_field_goal_attempts: number;
  three_point_field_goals_made: number;
  total_rebounds: number;
  turnovers: number;
  started: number;
  triple_double: number;
  free_throw_percentage: string;
  three_point_field_goal_percentage: string;
  field_goal_percentage: string;
  seconds: number;
  minutes_display: string;
  double_double: number;
  disqualified: number;
  franchise_score: number;
  opponent_score: number;
  team_win: number;
  team_loss: number;
};

export type PlayerStreakResult = {
  player_id: number;
  display_name: string;
  streak: number;
  first: number;
  last: number;
  games: number[];
  seasons: number[];
  sums: PlayerStreakResultStats,
  per_game: PlayerStreakResultStats,
};

export type TeamStreakResultStats = {
  assists: number;
  blocks: number;
  defensive_rebounds: number;
  double_double: number;
  dunks: number;
  field_goal_attempts: number;
  field_goals_made: number;
  free_throw_attempts: number;
  free_throws_made: number;
  offensive_rebounds: number;
  personal_fouls: number;
  points: number;
  points_in_paint: number;
  points_off_turnovers: number;
  second_chance_points: number;
  steals: number;
  three_point_field_goal_attempts: number;
  three_point_field_goals_made: number;
  total_rebounds: number;
  triple_double: number;
  turnovers: number;
  started: number;

  free_throw_percentage: string;
  three_point_field_goal_percentage: string;
  field_goal_percentage: string;

  assists_difference: number;
  blocks_difference: number;
  field_goals_difference: number;
  field_goal_percentage_difference: number;
  free_throws_difference: number;
  jump_balls_won_difference: number;
  personal_fouls_difference: number;
  points_difference: number;
  steals_difference: number;
  three_point_field_goals_difference: number;
  total_rebounds_difference: number;
  turnovers_difference: number;
  win_percentage_difference: number;

  franchise_score: number;
  opponent_score: number;
  team_win: number;
  team_loss: number;
};

export type TeamStreakResult = {
  franchise_id: number;
  franchise_name: string;
  streak: number;
  first: number;
  last: number;
  games: number[];
  seasons: number[];
  sums: TeamStreakResultStats,
  per_game: TeamStreakResultStats,
};

export type PlayerStreakTableRow = {
  rank: number;
  playerId: number;
  player_id: number;
  player__player_id: number;
  first: string;
  first_id: number;
  last: string;
  last_id: number;
  games: string;
  display_name: string;
  streak: number;
  seasons: number[],
  sums: PlayerStreakResultStats,
  per_game: PlayerStreakResultStats,
  per_game__points: string;
  per_game__total_rebounds: string;
  per_game__assists: string;
  per_game__blocks: string;
  per_game__steals: string;
  sum__started: number;
  sum__triple_double: number;
  sum__free_throw_percentage: string;
  sum__three_point_field_goal_percentage: string;
  sum__field_goal_percentage: string;
};

export type TeamStreakTableRow = {
  rank: number;
  franchise_id: number;
  franchise_name: string;
  first: string;
  first_id: number;
  last: string;
  last_id: number;
  games: string;
  streak: number;
  seasons: number[],
  sums: TeamStreakResultStats,
  per_game: TeamStreakResultStats,
  per_game__points: string;
  per_game__total_rebounds: string;
  per_game__assists: string;
  per_game__blocks: string;
  per_game__steals: string;
  sum__started: number;
  sum__free_throw_percentage: string;
  sum__three_point_field_goal_percentage: string;
  sum__field_goal_percentage: string;
};

export type PlayerSpanResult = {
  player_id: number;
  display_name: string;
  streak: number;
  first: number;
  last: number;
  game_ids: number[];
  seasons: number[];
  sums: PlayerStreakResultStats,
  per_game: PlayerStreakResultStats,
};

export type TeamSpanResult = {
  franchise_id: number;
  franchise_name: string;
  streak: number;
  first: number;
  last: number;
  game_ids: number[];
  games: number[];
  seasons: number[];
  sums: TeamStreakResultStats,
  per_game: TeamStreakResultStats,
};

export type PlayerSpanTableRow = {
  rank: number;
  playerId: number;
  player_id: number;
  player__player_id: number;
  first: string;
  first_id: number;
  last: string;
  last_id: number;
  games: string;
  display_name: string;
  streak: number;
  seasons: number[],
  sums: PlayerStreakResultStats,
  per_game: PlayerStreakResultStats,
  per_game__points: string;
  per_game__total_rebounds: string;
  per_game__assists: string;
  per_game__blocks: string;
  per_game__steals: string;
  sum__started: number;
  sum__triple_double: number;
  sum__free_throw_percentage: string;
  sum__three_point_field_goal_percentage: string;
  sum__field_goal_percentage: string;
};

export type TeamSpanTableRow = {
  rank: number;
  franchise_id: number;
  franchise_name: string;
  first: string;
  first_id: number;
  last: string;
  last_id: number;
  // games: string;
  streak: number;
  seasons: number[],
  sums: TeamStreakResultStats,
  per_game: TeamStreakResultStats,
  per_game__assists: string;
  per_game__blocks: string;
  per_game__defensive_rebounds: string;
  per_game__points: string;
  per_game__steals: string;
  per_game__total_rebounds: string;
  sum__started: number;
  sum__free_throw_percentage: string;
  sum__three_point_field_goal_percentage: string;
  sum__field_goal_percentage: string;
};

export type RecentSearchType = {
  id: string;
  favorite: boolean;
  finder: string;
  league: string;
  query_string: string;
  description: string;
  human_timestamp: string;
  updated_at: string;
  created_at: string;
};

export interface RecentSearchData extends PaginatedData {
  results: RecentSearchType[];
};
