import React from 'react';
import { Box, Button, Chip, Grid, styled, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import StyledModal from '../../atoms/StyledModal/StyledModal';
import ModalCheckbox from '../../atoms/ModalCheckbox/ModalCheckbox';
import { FooterStyles, OpenButtonStyles } from './StyleOverrides';
import { useFilterContext, useFilterDispatchContext } from '../../contexts/FilterContext';
import ModalRadioGroup from '../../atoms/ModalRadioGroup/ModalRadioGroup';
import ModalEqualityNumeric from '../../atoms/ModalEqualityNumeric/ModalEqualityNumberic';
import { ModalChipType, ModalFilterCategoriesType, ModalRadioFilterType } from '../../types/modal';
import { parseChipLabel } from './modal-filter-helpers';

const DEFAULT_OPEN_TITLE = 'Open Modal';

interface IProps {
  buttonTitle: string;
  filters: ModalFilterCategoriesType[];
  filterType: string;
  comingSoon?: boolean;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderBottom: `1px solid #eaecf0`,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingX: theme.spacing(2),
}));

function ModalFilters({
  buttonTitle,
  filters,
  filterType,
  comingSoon,
}: IProps) {
  const filterContext: any = useFilterContext();
  const filterDispatchContext: any = useFilterDispatchContext();

  const [open, setOpen] = React.useState(false);
  const chipsKey = `${filterType}Chips`;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onDoneClick = () => {
    setOpen(false);
  };

  const onResetClick = () => {
    const action = `${filterType}Reset`;
    filterDispatchContext({
      type: action,
    });
  };

  // map the chips to avoid passing a reference and leaking context state
  const getCurrentChips = (): ModalChipType[] => {
    const currentChips: ModalChipType[] = filterContext[chipsKey];
    // eslint-disable-next-line arrow-body-style
    const mappedChips = currentChips.map((a: ModalChipType) => {return {...a}});
    return mappedChips;
  }

  const chipsIndex = (option: string): number => {
    const chips = filterContext[chipsKey];
    return chips.findIndex((chip: ModalChipType) => chip.id === option);
  }

  const removeChip = (option: string) => {
    const index = chipsIndex(option);
    if (index > -1) {
      const currentChips: ModalChipType[] = getCurrentChips();
      currentChips.splice(index, 1);
      filterDispatchContext({
        type: `${filterType}ChipsUpdate`,
        chips: currentChips
      });
    }
  };

  const addChip = ({
    id,
    label,
    value = '',
    endValue = '',
    equality = '',
    radioGroupParent = '',
  }: ModalChipType) => {
    const index = chipsIndex(id);
    const currentChips: ModalChipType[] = getCurrentChips();
    if (index === -1) {
      currentChips.push({
        id,
        label,
        radioGroupParent,
        equality,
        endValue,
        value,
      });
      filterDispatchContext({
        type: `${filterType}ChipsUpdate`,
        chips: currentChips
      });
    }
  };

  const replaceChip = (
    option: string,
    {
      id,
      label,
      value = '',
      endValue = '',
      equality = '',
      radioGroupParent = '',
    }: ModalChipType
  ) => {
    const index = chipsIndex(option);
    const currentChips: ModalChipType[] = getCurrentChips();
    currentChips.splice(index, 1);
    currentChips.push({
      id,
      label,
      radioGroupParent,
      equality,
      endValue,
      value,
    });
    filterDispatchContext({
      type: `${filterType}ChipsUpdate`,
      chips: currentChips
    });
  }

  const addOrUpdateChip = ({
    id,
    label,
    value = '',
    endValue = '',
    equality = '',
    radioGroupParent = '',
  }: ModalChipType) => {
    const index = chipsIndex(id);
    const currentChips: ModalChipType[] = getCurrentChips();
    if (index === -1) {
      currentChips.push({
        id,
        label,
        radioGroupParent,
        equality,
        endValue,
        value,
      });
      filterDispatchContext({
        type: `${filterType}ChipsUpdate`,
        chips: currentChips
      });
    } else {
      currentChips[index] = {
        id,
        label,
        radioGroupParent,
        equality,
        endValue,
        value,
      }
      filterDispatchContext({
        type: `${filterType}ChipsUpdate`,
        chips: currentChips
      });
    }
  };

  const handleDeleteChip = (chip: ModalChipType) => {
    if (chip.radioGroupParent && chip.radioGroupParent.length > 0) {
      filterDispatchContext({
        type: 'clearModalRadioFilter',
        filter: {
          key: chip.radioGroupParent,
          filterGroup: filterType,
        }
      });
    } else if (chip.equality && chip.equality.length > 0) {
      filterDispatchContext({
        type: 'clearAdvancedFilter',
        filter: {
          key: chip.id,
          filterGroup: filterType,
        }
      });
    } else {
      filterDispatchContext({
        type: 'changeModalFilter',
        filter: {
          key: chip.id,
          value: false,
          filterGroup: filterType,
        }
      });
    }
    removeChip(chip.id);
  };

  const onCheckBoxChange = (option: string, selected: boolean, chipAbbr: string) => {
    filterDispatchContext({
      type: 'changeModalFilter',
      filter: {
        key: option,
        value: selected,
        filterGroup: filterType,
      }
    });

    if (selected) {
      addChip({ id: option, label: chipAbbr });
    } else {
      removeChip(option);
    }
  };

  const onCheckBoxWithValueChange = (
    option: string,
    selected: boolean,
    selectedEquality: string,
    chipAbbr: string,
  ) => {
    filterDispatchContext({
      type: 'changeModalCheckboxWithInput',
      filter: {
        key: option,
        selected,
        value: '',
        equalityOption: selectedEquality,
        filterGroup: filterType,
      }
    });

    if (selected) {
      const chipLabel = parseChipLabel(chipAbbr, '', '', '>=');
      addChip({ id: option, label: chipLabel, equality: '>=', value: '', });
    } else {
      removeChip(option);
    }
  };

  const handleRadioButtonChange = (option: string, value: string, chipAbbr: string) => {
    const previousSelect = filterContext[filterType][option];
    filterDispatchContext({
      type: 'changeModalFilter',
      filter: {
        key: option,
        value,
        filterGroup: filterType,
      }
    });
    filterDispatchContext({
      type: 'changeModalFilter',
      filter: {
        key: `${option}_value`,
        value: '',
        filterGroup: filterType,
      }
    });
    filterDispatchContext({
      type: 'changeModalFilter',
      filter: {
        key: `${option}_end_value`,
        value: '',
        filterGroup: filterType,
      }
    });
    const index = chipsIndex(previousSelect);
    if (index > -1) {
      const newChip = { id: value, label: chipAbbr, radioGroupParent: option};
      replaceChip(previousSelect, newChip);
    } else {
      addChip({ id: value, label: chipAbbr, radioGroupParent: option});
    }
  };

  const setInputValue = (
    value: string,
    groupContextId: string,
    filter: ModalRadioFilterType,
    end: boolean = false
  ) => {
    const valueString = end ? 'end_value' : 'value';
    const groupValueId = `${groupContextId}_${valueString}`;

    filterDispatchContext({
      type: 'changeModalFilter',
      filter: {
        key: groupValueId,
        value,
        filterGroup: filterType,
      }
    });
    filterDispatchContext({
      type: 'changeModalFilter',
      filter: {
        key: groupContextId,
        value: filter.id,
        filterGroup: filterType,
      }
    });

    if (end) {
      const inputValue = filterContext[filterType][`${groupContextId}_value`];
      const chipLabel = parseChipLabel(filter.chip, inputValue, value);
      addOrUpdateChip({ id: filter.id, label: chipLabel, radioGroupParent: groupContextId});
    } else {
      const endValue = filterContext[filterType][`${groupContextId}_end_value`];
      const chipLabel = parseChipLabel(filter.chip, value, endValue);
      addOrUpdateChip({ id: filter.id, label: chipLabel, radioGroupParent: groupContextId});
    }
  };

  const setCheckboxInputValue = (
    option: string,
    value: string,
    equalityOption: string,
    chipAbbr: string,
  ) => {
    filterDispatchContext({
      type: 'changeModalCheckboxWithInput',
      filter: {
        key: option,
        selected: true,
        value,
        equalityOption,
        filterGroup: filterType,
      }
    });
    const chipLabel = parseChipLabel(chipAbbr, value, '', equalityOption);
    addOrUpdateChip({
      id: option,
      label: chipLabel,
      value,
      equality: equalityOption,
    })
  };

  const mapFilters = (category: ModalFilterCategoriesType) =>
    category.filters.map((filter) => {
      // const modalContext = filterContext[filterType];
      // const localFilterContext = modalContext[filter.id];
      switch (filter.filterType) {
        case 'radio': {
          return (
            <ModalRadioGroup
              key={`${filter.id}-option`}
              filterId={filter.id}
              selectedValue={filterContext[filterType][filter.id]}
              handleRadioButtonChange={handleRadioButtonChange}
              radioFilters={filter.radioGroup || []}
              inputValue={filterContext[filterType][`${filter.id}_value`]}
              saveInputValue={setInputValue}
              inputEndValue={filterContext[filterType][`${filter.id}_end_value`]}
            />
          );
        }
        case 'equalityNumeric': {
          // if (localFilterContext === undefined) {
          //   console.log(filter);
          //   console.log(filterType);
          //   console.log(localFilterContext);
          // }
          return (
            <ModalEqualityNumeric
              key={`${filter.id}-numeric-equality-option`}
              filter={filter}
              selected={filterContext[filterType][filter.id].selected}
              selectedEquality={filterContext[filterType][filter.id].equalityOption}
              inputValue={filterContext[filterType][filter.id].value}
              onCheckBoxChange={onCheckBoxWithValueChange}
              onInputValueChange={setCheckboxInputValue}
            />
          );
        }
        default:
          return (
            <ModalCheckbox
              key={`${filter.id}-option`}
              label={filter.label}
              subLabel={filter.subLabel}
              labelPlacement="end"
              selected={filterContext[filterType][filter.id]}
              onChange={(_event, value) => onCheckBoxChange(filter.id, value, filter.chip)}
            />
          );
      }
    });

  return (
    <Grid
      container
      spacing={0}
      alignItems="flex-start"
      paddingX={2}
      paddingY={1}
    >
      <Grid item xs={12}>
        <Button onClick={handleOpen} variant="text" startIcon={<AddCircleIcon />} sx={OpenButtonStyles}>
          { buttonTitle || DEFAULT_OPEN_TITLE}
        </Button>
        {
          filterContext[chipsKey].map((chip: ModalChipType) => (
            <Chip
              key={`${chip.id}-chip`}
              label={chip.label}
              deleteIcon={<CancelIcon />}
              onDelete={() => handleDeleteChip(chip)}
              sx={{
                marginX: '3px',
                color: '#175CD3',
                backgroundColor: '#EFF8FF',
                '& .MuiChip-deleteIcon': {
                  color: '#175CD3',
                  fontSize: '1.25em',
                },
              }}
            />
          ))
        }
      </Grid>
      <StyledModal open={open} handleClose={handleClose}>
        <Box style={{ height: '100%'}}>
          <Box style={{ width: '100%', height: '6%', marginTop: '20px', marginLeft: '10px' }}>
            <Typography variant="h6" component="div" fontWeight='fontWeightBold'>
              { buttonTitle }
            </Typography>
          </Box>
          <Box style={{ height: '84%', overflowY: 'scroll', width: '100%' }}>
            { comingSoon ? (
              <Typography variant='h6' marginTop={5} marginLeft={2}>Coming Soon...</Typography>
            ) : filters.map((category) => (
              <Accordion
                key={category.category}
                defaultExpanded={category.defaultExpanded !== undefined ? category.defaultExpanded : true}

                // TODO: switch to slotProps after upgrading to mui-material 5.15.5+
                // slotProps={{
                //   transition: { unmountOnExit: true }
                // }}
                TransitionProps={{
                  unmountOnExit: (filters.length > 3),
                }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="body1" component="div" fontWeight='fontWeightBold'>
                    { category.categoryLabel }
                    { category.categorySubLabel && (<>
                      <br />
                      <Typography variant="body2" component="div" fontWeight='fontWeightRegular'>
                        { category.categorySubLabel }
                      </Typography>
                    </>)}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid item xs={12} marginY={1} marginX={2}>
                    {mapFilters(category)}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
          <Box style={{ width: '100%', height: '10%' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              paddingY={1}
              paddingX={2}
              sx={FooterStyles}
              className="advanced-filter-footer"
            >
              <Grid item xs={6}>
                <Button variant="text" onClick={onResetClick}>
                  Reset
                </Button>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Button variant="contained" onClick={onDoneClick}>
                  Done
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </StyledModal>
    </Grid>
  );
}

export default ModalFilters;
